import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Chip from '@mui/material/Chip';
import { NavLink } from 'react-router-dom';
import { usePopupMenuStyles } from './popup-menu.styles';
import { PopupMenuProps } from './popup-menu.types';
import { capitalizeWords } from './popup-menu.utils';

function PopupMenu({
  title,
  options,
  open,
  anchorEl,
  onClickAway,
  onLeave,
  onEnter,
  ref,
}: PopupMenuProps) {
  const canBeOpen = open && !!anchorEl;
  const id = canBeOpen ? 'spring-popper' : undefined;
  const classes = usePopupMenuStyles();

  // Close the popup when pointer leaves the popup or anchor element
  const pointerLeaveHandler = (event: React.PointerEvent<HTMLElement>) => {
    // this is true if the pointer is moving from the popup to the anchor element
    // in this case we don't want to close the popup
    if (anchorEl?.contains(event.relatedTarget as Node)) {
      return;
    }
    onLeave(event);
  };

  return (
    <Popper
      ref={ref}
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="right-start"
      onPointerEnter={onEnter}
      onPointerLeave={pointerLeaveHandler}
      className={classes.popperRoot}
    >
      <Paper className={classes.root}>
        <Typography className={classes.title}>
          {capitalizeWords(title)}
        </Typography>
        <ClickAwayListener onClickAway={event => open && onClickAway?.(event)}>
          <MenuList className={classes.menuList}>
            {options.map(option => {
              return (
                <MenuItem
                  key={option.title}
                  onClick={() => onLeave()}
                  className={classes.option}
                >
                  {option.icon()}
                  <Link
                    component={NavLink}
                    to={option.to}
                    className={classes.link}
                    underline="none"
                    {...(option.external ? { target: '_blank' } : {})}
                  >
                    <Box className={classes.optionContainer}>
                      <Box className={classes.optionLabels}>
                        <Typography className={classes.optionTitle}>
                          {option.title}
                        </Typography>
                        {option.subtitle && (
                          <Typography
                            className={`${classes.optionSubtitle} ${!option.tag && `${classes.optionSubtitle}--wrap`} `}
                          >
                            {option.subtitle}
                          </Typography>
                        )}
                      </Box>
                      {option.tag && (
                        <Chip
                          label={option.tag}
                          size="small"
                          className={classes.optionTag}
                        />
                      )}
                    </Box>
                  </Link>
                </MenuItem>
              );
            })}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
}

export default PopupMenu;
