import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const catalogYamlGeneratorPlugin = createPlugin({
  id: 'catalog-yaml-generator',
  routes: {
    root: rootRouteRef,
  },
});

export const CatalogYamlGeneratorPage = catalogYamlGeneratorPlugin.provide(
  createRoutableExtension({
    name: 'CatalogYamlGeneratorPage',
    component: () =>
      import('./components/CatalogYamlGenerator').then(
        m => m.CatalogYamlGenerator,
      ),
    mountPoint: rootRouteRef,
  }),
);
